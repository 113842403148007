@import 'app.scss';

.container {
  @include container(xl);

  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-large);

  @include from-breakpoint(md) {
    margin-top: var(--spacing-huge);
  }
}

.intro {
  font-size: var(--font-size-h3);
  max-width: var(--content-width);
}
