/**
 * Loader component
 */

@keyframes pending-spinner {
  from {
    transform: translate(50%, -50%);
  }

  to {
    transform: translate(50%, -50%) rotate(1turn);
  }
}

.container {
  position: relative;
  min-height: 150px;
}

.overlay {
  flex: 100% 1 1;
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + var(--spacing-tiny) * 2);
  width: calc(100% + var(--spacing-tiny) * 2);
  border-radius: var(--border-radius-base);
  background-color: rgba(var(--color-gray-900-rgb), 0.05);
  margin: calc(-1 * var(--spacing-tiny));
  z-index: 1;
}

.spinner {
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: pending-spinner 750ms linear infinite;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
