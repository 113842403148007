@import 'app.scss';

.container {
  @include container(xl);
}

.title {
  margin-bottom: var(--spacing-medium);
}

.listContainer {
  max-width: var(--content-width);
}

.list {
  @include list-clean;
}

.item {
  margin-bottom: var(--spacing-base);
}
