@import 'app.scss';

.button {
  position: relative;
  display: block;
  background-color: transparent;
  padding: var(--spacing-small) var(--spacing-medium);
  color: var(--color-button-text);
  font-size: var(--font-size-button);
  font-weight: var(--font-weight-bold);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-shadow: inset 0 -0.1rem rgb(0 0 0 / 10%);
  z-index: 1;

  @include from-breakpoint(md) {
    display: inline-block;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--border-radius-base);
    background-color: var(--color-brand);
    z-index: -1;
    transition: filter 0.2s;
  }

  &:hover {
    color: var(--color-button-text);

    &::before {
      filter: brightness(1.25);
    }
  }

  &.buttonFullWidth {
    display: block;
  }
}
