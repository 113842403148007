/**
 * Card
 */

.card {
  position: relative;
  color: var(--text-color, var(--color-text-base));
  background-color: var(--background-color, var(--color-white));
  border: 1px solid;
  border-radius: var(--border-radius-base);
  border-color: var(--border-color, transparent);
}

.content {
  position: relative;
  padding: var(--padding, var(--spacing-base));

  & > :last-child {
    margin-bottom: 0;
  }
}

.error,
.success,
.warning,
.info {
  --padding: var(--spacing-small);
  --margin: 0 0 var(--spacing-small);
}

.error {
  --text-color: var(--color-error-900);
  --background-color: var(--color-error-100);
  --border-color: var(--color-error-500);
}

.success {
  --text-color: var(--color-success-900);
  --background-color: var(--color-success-100);
  --border-color: var(--color-success-500);
}

.warning {
  --text-color: var(--color-warning-900);
  --background-color: var(--color-warning-100);
  --border-color: var(--color-warning-500);
}

.info {
  --text-color: var(--color-primary-900);
  --background-color: var(--color-primary-100);
  --border-color: var(--color-primary-500);
}
