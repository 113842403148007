@mixin from-breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
      + 'Please make sure it is defined in `$grid-breakpoints` map.';
  }
}

@mixin until-breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: calc(#{map-get($breakpoints, $breakpoint)} - 1px)) {
      @content;
    }
  } @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
      + 'Please make sure it is defined in `$grid-breakpoints` map.';
  }
}
