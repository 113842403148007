@import 'app.scss';

.container {
  position: relative;
  height: 250px;

  @include from-breakpoint(md) {
    height: 355px;
  }
}
