.select {
  position: relative;
  display: inline-block;
  width: auto;
}

.selectLabel {
  display: block;
}

.selectContainer {
  display: block;
  position: relative;
}

.selectInput {
  vertical-align: middle;
  border: var(--border-base);
  border-radius: var(--border-radius-base);
  background-color: transparent;
  padding: var(--spacing-small) var(--spacing-base);
  padding-right: calc(var(--spacing-base) + var(--font-size-base));
  color: var(--color-black);
  font-size: var(--font-size-button);
  font-weight: var(--font-weight-bold);
}

.selectIcon {
  position: absolute;
  top: 50%;
  right: var(--spacing-small);
  width: var(--font-size-base);
  height: var(--font-size-base);
  transform: translateY(-50%);
  pointer-events: none;
  user-select: none;
  color: var(--color-black);
}
