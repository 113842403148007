@import 'app.scss';

.header {
  position: relative;
  padding: var(--spacing-medium) 0;
  font-weight: var(--font-weight-bold);

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    height: 12px;
    box-shadow: inset 0 12px 10px -10px rgba(0, 0, 0, 0.07);
  }
}

.logo {
  margin-right: var(--spacing-large);

  img {
    width: auto;
    height: 50px;
    margin-bottom: 0;
  }
}

.container {
  @include container(xl);

  display: flex;
  align-items: center;
}

.link {
  position: relative;
  display: flex;
  align-items: center;
}

.linkIcon {
  width: var(--font-size-base);
  height: var(--font-size-base);
  margin-left: var(--spacing-tiny);
  fill: var(--color-brand);
}

.websiteUrl {
  margin-left: auto;
}
