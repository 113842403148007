@import 'app.scss';

.item {
  display: block;
  padding: var(--spacing-medium) var(--spacing-base);
  border: var(--border-base);
  border-radius: var(--border-radius-base);
  text-decoration: none;
  color: var(--color-gray-900);
}

.header {
  @include from-breakpoint(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-base);
  }

  .meta {
    @include until-breakpoint(md) {
      margin-bottom: var(--spacing-tiny);
    }
  }
}

.title {
  font-size: var(--font-size-h3);
  margin-bottom: var(--spacing-base);
  color: var(--color-black);

  @include from-breakpoint(md) {
    margin-bottom: 0;
  }
}

.content {
  @include from-breakpoint(md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.metaGroup {
  @include until-breakpoint(md) {
    margin-bottom: var(--spacing-base);
  }
}

.meta {
  display: flex;
  align-items: center;

  & + .meta {
    margin-top: var(--spacing-tiny);
  }
}

.icon {
  fill: var(--color-brand);
  width: var(--font-size-base);
  height: var(--font-size-base);
  margin-right: var(--spacing-tiny);
}
